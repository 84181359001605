import { API_URL } from "../../App";
import axios from "axios";

export default class APIManager{
    
    public static fetch(api:string,data:any,callback?:((error:boolean,response?:any)=> any )){

        var formData = new FormData();
        Object.keys(data).forEach((key) => {
            if( data[key] != null && data[key] != undefined ){
                formData.append(key, data[key])
            }
        });

       axios.post(API_URL + "/" + api,formData,{
        }).then((response)=>{
            if(response.status != 200 || response.data == undefined){
                if(callback !== undefined){
                    callback(true,response.data)
                }
            }else{
                if(callback !== undefined){
                    callback(false,response.data)
                }
            }
            
        }).catch((error)=>{
            if(callback !== undefined){
                callback(true,error)
            }
        })
    }
}