import StoreManager from "./managers/StoreManger/StoreManager";
import React, { lazy, Suspense } from "react";
import MainMenu from "./components/MainMenu";
import { Switch, Route } from "react-router";

import PageIndex from "./pages";
import Page404 from "./pages/404";
import Login from "./pages/Login";


import StateModel, { StateSession } from "./managers/StoreManger/state/StateModel";

const PageCustomers = lazy(() => import("./pages/CustomerList"));
const PageLoans = lazy(() => import("./pages/LoanList"));
const PagePromotors = lazy(() => import("./pages/PromotorList"));
const PageUsers = lazy(() => import("./pages/UserList"));
const PageReceiptList = lazy(() => import("./pages/ReceiptsList"));

const CustomerProfile = lazy(() => import("./pages/CustomerProfile"));
const PromotorProfile = lazy(() => import("./pages/PromotorProfile"));
const LoanProfile = lazy(() => import("./pages/LoanProfile"));

const FormPromotor = lazy(() => import("./pages/FormZonePromotor"));
const FormUser = lazy(() => import("./pages/FormUser"));
const FormRoute = lazy(() => import("./pages/FormRoute"));

const FormLoan = lazy(() => import("./pages/FormLoan"));
const FormCustomer = lazy(() => import("./pages/FormCustomer"));

const RecoveryForm = lazy(() => import("./pages/RecoveryForm"));
const Recovery = lazy(() => import("./pages/Recovery"));

const PageRoutes = lazy(() => import("./pages/Routes"));
const PageRoute = lazy(() => import("./pages/Route"));
const PageLists = lazy(() => import("./pages/Lists"));

const PageReports = lazy(() => import("./pages/Reports"));
const PageIndividualReports = lazy(() => import("./pages/IndividualReports"));
const PageFormReceipts = lazy(() => import("./pages/FormReceipts"));
const PageFormTaxpayer = lazy(() => import("./pages/FormTaxpayer"));
const PagePrintReceipts = lazy(() => import("./pages/PrintReceipts"));

const PageSearch = lazy(() => import("./pages/Search"));

const PageCaja = lazy(() => import("./pages/Caja"));
const PageCajaGrupo = lazy(() => import("./pages/CajaGrupo"));

export default StoreManager.connect((state:StateModel)=> {
    return { session:state.session }
})<any>((props:{session:StateSession})=>{
    if(props.session === undefined){
        return null
    }

    if(props.session.isLogged === false){
        return (
            <div className="main-container">
                <Login/>
            </div>
        );
    }

    return (
        <div className="main-container">
            <MainMenu></MainMenu>
            <div className="screen-container">
                <Suspense fallback={<div/>}>
                    <Switch>  
                        <Route path="/" exact component={PageIndex}></Route>
                        <Route path="/form-customer/:idElement?" exact component={FormCustomer}></Route>
                        <Route path="/form-promotor/:idElement?" exact component={FormPromotor}></Route>
                        <Route path="/form-user/:idElement?" exact component={FormUser}></Route>
                        <Route path="/form-route/:idExecutive?/:day?" exact component={FormRoute}></Route>
                        <Route path="/form-loan/:idElement?/:idZone?" exact component={FormLoan}></Route>
                        <Route path="/recovery-form/:idElement?/:idZone?" exact component={RecoveryForm}></Route>
                        <Route path="/recovery/:filter?" exact component={Recovery}></Route>
                        <Route path="/customers/:filter?" exact component={PageCustomers}></Route>
                        <Route path="/promotors/:filter?" exact component={PagePromotors}></Route>
                        <Route path="/users/:filter?" exact component={PageUsers}></Route>
                        <Route path="/routes/:user?/:day?" exact component={PageRoutes}></Route>
                        <Route path="/form-receipts/:idElement?" exact component={PageFormReceipts}></Route>
                        <Route path="/form-taxpayer/:idElement?" exact component={PageFormTaxpayer}></Route>
                        <Route path="/receipts-list/:filter?" exact component={PageReceiptList}></Route>
                        <Route path="/print-receipts/:idElement/:rfc?" exact component={PagePrintReceipts}></Route>
                        <Route path="/loans/:filter?" exact component={PageLoans}></Route>
                        <Route path="/customer/:code" exact component={CustomerProfile}></Route>
                        <Route path="/promotor/:code" exact component={PromotorProfile}></Route>
                        <Route path="/loan/:code" exact component={LoanProfile}></Route>
                        <Route path="/route/:date" exact component={PageRoute}></Route>
                        <Route path="/lists" exact component={PageLists}></Route>
                        <Route path="/reports/:period?/:value?" exact component={PageReports}></Route>
                        <Route path="/individual-reports/:period?/:value?" exact component={PageIndividualReports}></Route>
                        <Route path="/search" exact component={PageSearch}></Route>
                        <Route path="/caja" exact component={PageCaja}></Route>
                        <Route path="/caja-grupo" exact component={PageCajaGrupo}></Route>
                        <Route component={Page404}></Route> 
                    </Switch>
                </Suspense>
                {/*<div className="watermark ignore-print">Desarrollado por <a href="https://www.scefira.com" rel="nofollow" target="blank">scefira technologies</a></div>*/}
            </div>
        </div>
    ) 
})