import React from "react";
import * as CurrencyFormat from 'react-currency-format';

interface CurrencyComponentProps {
    value: any;
    fixed?: boolean;
}

export default class Currency extends React.Component<CurrencyComponentProps>{
    render(){
        return <CurrencyFormat value={this.props.fixed?(this.props.value).toFixed(2):this.props.value} displayType={'text'} thousandSeparator={true} prefix={'$'} />
    }
}