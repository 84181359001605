import React from "react";
import SecurityManager from "../managers/SecurityManager/SecurityManager";

export interface AccessibleSectionProps{
    credential?:string|number|string[]|number[];
}


export default class AccessibleSection extends React.Component<AccessibleSectionProps>{
    public render(){
        return (
            <React.Fragment>
                {SecurityManager.hasCredential(this.props.credential?this.props.credential:[])?this.props.children:null}
            </React.Fragment>
        );
    }
}