import React from "react";
import APIManager from "../managers/APIManager/APIManager";
import { History }from '../App';
import { Icon } from "@material-ui/core";

export default class extends React.Component {

    state = {
        loans: [] as any[]
    }

    public fetchData(){
        APIManager.fetch("user/get-before-exp-loans.php", {}, (error, response) => {
            if (error === false) {
                this.setState({ loans:response.loans});
            }
        });
    }

    public componentDidMount(){
        this.fetchData();
    }

    public render(){
        return (
            <div>
                <h1>Prestamos mayores a 14 semanas próximos a vencer</h1>
                {this.state.loans.map((loan)=>{
                    let expire = new Date(loan.initialDate);
                    let cycles = loan.status == 1 ? loan.cycles + loan.penaltyDays : loan.cycles ;
                    expire.setDate(expire.getDate() + cycles * 7);

                    let expireString  = expire.getDate()  + "-" + (expire.getMonth()+1) + "-" + expire.getFullYear() + " ";

                    return (<div style={{padding:10, cursor:"pointer"}} onClick={()=>{
                        History.push('/loan/'+loan.id)
                    }}>
                         <Icon>warning</Icon> {loan.id} - {loan.customer.person.name} - Expira el {expireString}
                    </div>);
                })}
            </div>
        );
    }
    
}