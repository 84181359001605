import { isArray, isNumber } from "util";
import StoreManager from "../StoreManger/StoreManager";

export default class SecurityManager{

    /**
     * Check for a credential or array of credentials by NAME or id (numeric)
     * @param cred_name 
     */
    public static hasCredential(cred_name:string|number|string[]|number[]){
        if(isArray(cred_name) === true){
            for(var i = 0; i< (cred_name as any[]).length;i++){
                if (SecurityManager.hasCredential(cred_name[i]) === true){ return true; }
            }
        }else{
            if(StoreManager.getState().session === undefined || StoreManager.getState().session!.isLogged === false || StoreManager.getState().session!.credentials === undefined){
                return false;
            }

            var credentials:any[] = StoreManager.getState().session!.credentials as any[]

            for(var i = 0; i< credentials.length;i++){
                var cred = credentials[i];

                if(isNumber(cred_name)){
                    if(cred.id === cred_name){ return true; }
                }else{
                    if(cred.name === cred_name){ return true; }
                }
            }

            return false;
        }
    }
}