import {createStore, Store, Action} from "redux";
import { connect, Connect } from "react-redux"; 
import CustomAction from "./CustomAction";
import { enableBatching, batchActions } from "redux-batched-actions";
import StateModel from "./state/StateModel";
import SystemReducer from "./reducers/SystemReducer";

export default class StoreManager{

    private static initialState = { session: { isLogged: false , fetched:false}}
    
    /**
     *  Map de acciones para el reductor 
     */
    private static actions:{ [key:string]:CustomAction; } = {};

    /**
    * Store nativo de Redux
    */
    private static CinderNativeStore:Store<StateModel> = createStore(enableBatching(StoreManager.reducer),{});
        
    /**
     * Inicializador de StoreManager
     */
    public static initialice(){        
        this.registerReducersNamespace(SystemReducer);
    }

    /**
     * Función reductora, esta es la función que será llamada por redux AUTOMATICAMENTE cada vez que se dispare una acción
     */
    public static reducer(state:StateModel,action:Action){
        if(StoreManager.actions[action.type] !== undefined){
            return StoreManager.actions[action.type].reduct(state,action);
        }else{
            console.warn(`Undefined Action '${action.type}'`);
            return state;
        }
    }

    /**
     * Añade un nuevo action al reductor de forma dinamica
     * @param key 
     * @param action 
     */
    public static registerReducer(action: CustomAction):void{
        if(this.actions[action.typeName] === undefined){
            this.actions[action.typeName] = action;
        }else{
            console.error("The action '" + action.typeName +"' is already registered");
        }
    }

    /**
     * Registra todos los actions dentro de un vector
     * @param key 
     * @param action 
     */
    public static registerReducersNamespace(actions:{ [key:string]:any} ):void{
        var keynames = Object.keys(actions);
        keynames.forEach((keyname:string)=>{
            StoreManager.registerReducer(new actions[keyname]());
        })
    }

    /**
     * @param action Nombre o ID de la acción a ser ejecutada
     * @param args Objecto que contiene los datos que usará el action como parametro
     */
    public static dispatch(action:Action|Action[]):void{
        if(Array.isArray(action)){
            this.CinderNativeStore.dispatch(batchActions(action as any));
        }else{
            this.CinderNativeStore.dispatch(action as any);
        }
    }

    /**
     * Devuelve el state actual de este store
     */
    public static getState():StateModel{
        return this.CinderNativeStore.getState();
    }

    /**
     * 
     * @param action 
     */
    public static getReducer(typeName:string):CustomAction|undefined{
        return this.actions[typeName];
    }


    /**
     * @returns Native Redux store for Cinder
     */
    public static getStore():Store<any>{
        return this.CinderNativeStore;
    }

    public static connect:Connect = connect;
}