import React from "react";
import { Link } from "react-router-dom";
import "./Navigator.scss";
import StoreManager from "../managers/StoreManger/StoreManager";
import StateModel, { StateSession } from "../managers/StoreManger/state/StateModel";
import { Icon, Button, Menu, MenuItem } from "@material-ui/core";
import { History } from "../App";
import APIManager from "../managers/APIManager/APIManager";
export default StoreManager.connect( (state:StateModel)=> {

  return { session:state.session }
})<any>((props:{session:StateSession}) => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }
 
  function handleClose() {
   
    setAnchorEl(null);
  }

  function handleCloseSession(){
    APIManager.fetch('common/logout.php', {}, (error, response)=>{
      if(error === false && response.status === 'success'){
        console.log(response)
        History.go(0);
      } else{
        console.log(error)
      }
    });
  }

    return (
      <div className="navigator">
        <div className="enterprise">
          <Link to="/" style={{backgroundImage:"url('logo.png')"}}></Link>
        </div>
        <div style={{textAlign:"center"}} className="title-label">
          {document.title}
        </div>
        <div>
          { props.session != undefined && <Button onClick={handleClick} className="user-name">{props.session.nick}<Icon>person</Icon></Button> }
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            
          >
           
            <MenuItem onClick={handleCloseSession}>Cerrar sesión</MenuItem>
        </Menu>
        </div>
      </div>
    )
  }
);

