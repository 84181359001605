 import React from "react";
import { Formik, FormikConfig, Form } from "formik";
import { TextField, Button, CircularProgress, Grid, Typography} from "@material-ui/core";
import APIManager from "../managers/APIManager/APIManager";
import StoreManager from "../managers/StoreManger/StoreManager";
import SystemReducer from "../managers/StoreManger/reducers/SystemReducer";
import * as Yup from 'yup';

const SignupSchema = Yup.object().shape({
  user: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
  
}); 
export var LoginFormDataProps:FormikConfig<any> = {
    initialValues : {
        user: '', 
        password: '' 
    },
    initialStatus : {
        error: false,
    },

    onSubmit : (values, tools) => {
        tools.setSubmitting(true);

        APIManager.fetch('common/login.php', values, (error,response)=>{
            StoreManager.dispatch(new SystemReducer.boot().makeAction(response));
            tools.setStatus({error: error || StoreManager.getState().session === undefined || StoreManager.getState().session!.isLogged === false})
            tools.setSubmitting(false);
        });   

    },

    render:(props)=>{


        var ButtonContent:any = props.isSubmitting?<CircularProgress size={25}/>:"Iniciar sesión";
        var ErrorMsg = '';
        if(props.status.error === true && props.isSubmitting == false){
            ButtonContent = "Reintentar";
            ErrorMsg = 'Error al entrar al sistema';
        }
        return (
            <Form>    
            <Grid container lg={12} xs={12}>
                <Grid item lg={12} xs={12}>
                    <TextField 
                        name="user" 
                        label="Nombre o email"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.user} 
                        helperText={Boolean(props.touched.user)?props.errors.user:""}
                    
                        style={styles.textField}
                        margin="normal"
                    />
                </Grid>
                <Grid item lg={12} xs={12}>
                    <TextField 
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.password} 
                        type="password"
                         name="password" 
                        label="Constraseña"
                        helperText={Boolean(props.touched.user)?props.errors.user:""}
   
                        style={styles.textField}
                        margin="normal"
                    />
                </Grid>
                <Grid item lg={12} xs={12}>
                <Typography style={styles.errorText}>{ErrorMsg}</Typography>
            
                    <Button disabled={props.isSubmitting} type="submit" className="button-submit"   variant="contained" color="primary" style={styles.button}>
                    { ButtonContent }
                    </Button>
                
                </Grid>
            </Grid >

                
            
            </Form>
        );
    }
}


export default class LoginForm extends React.Component{
    public render(){
        return (
            <Formik {...LoginFormDataProps}/>
        );
    }
}

        
const styles = {
           
    textField: {
        marginTop: 8,
        width: '100%',
      },
    button: {
        width: '100%',
        marginTop: 15
    },
    errorText:{
        marginTop:8,
        width: '100%',
        fontSize: 12,
        color: 'red',
        textAlign: 'center'
    },
    
  } as any;