import CustomReducer from "../CustomReducer";
import StateModel, { StateSession } from "../state/StateModel";

export default {
    /**
     * Funcion de inicialización del sistema, establece la sessión y cachea datos iniciales 
     */
    "boot" : class extends CustomReducer<any>{
        public typeName: string = "system.boot";
        public reduct(state: StateModel, action:{session:StateSession}) {
            var { session } = action;
            console.log("c",{...state,session:session});
            return {...state,session:session}
        }
    },

};