import React, { CSSProperties } from "react";
import "./MainMenu.scss";
import { List, ListItem, ListItemIcon, ListItemText, Icon } from "@material-ui/core";
import { Link } from "react-router-dom";
import AccessibleSection from "./AccessibleSection";

export interface MainMenuListProps{
    className?:string;
    style?:CSSProperties;
    id?:string;
    key?:string;
}

export default class MainMenuList extends  React.Component<MainMenuListProps>{
    public render(){
        return (
            <List component="nav" {...this.props} >

                <ListItem button component={ p => <Link {...p} to="/"/>}>
                    <ListItemIcon>
                        <Icon>home</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Inicio" />
                </ListItem>  

                <AccessibleSection credential={["ADMIN","SUPERVISOR","CAPTURIST","EXECUTIVE"]}>
                        <ListItem button component={ p => <Link {...p} to="/search"/>}>
                            <ListItemIcon>
                                <Icon>search</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Consultar" />
                        </ListItem>  
                </AccessibleSection>

                <AccessibleSection credential={["CAJA"]}>
                        <ListItem button component={ p => <Link {...p} to="/caja"/>}>
                            <ListItemIcon>
                                <Icon>inbox</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Caja" />
                        </ListItem>  
                </AccessibleSection>

                   <AccessibleSection credential={["ADMIN"]}>
                        <ListItem button component={ p => <Link {...p} to="/users"/>}>
                            <ListItemIcon>
                                <Icon>account_circle</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Usuarios" />
                        </ListItem>  
                    </AccessibleSection>
                    

                    <AccessibleSection credential={["ADMIN","SUPERVISOR"]}>
                        <ListItem button component={ p => <Link {...p} to="/promotors"/>}>
                            <ListItemIcon>
                                <Icon>person_pin_circle</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Grupos y Prom..." />
                        </ListItem>
                    </AccessibleSection>

                    <AccessibleSection credential={["ADMIN","SUPERVISOR","CAPTURIST"]}>
                        <ListItem button component={ p => <Link {...p} to="/customers/debtors"/>}>
                            <ListItemIcon>
                                <Icon>face</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Clientes" />
                        </ListItem>
                    </AccessibleSection>

                    <AccessibleSection credential={["ADMIN","SUPERVISOR","CAPTURIST"]}>
                        <ListItem button component={ p => <Link {...p} to="/loans/active"/>}>
                            <ListItemIcon>
                                <Icon>monetization_on</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Préstamos" />
                        </ListItem>
                    </AccessibleSection>

                    <AccessibleSection credential={["ADMIN","SUPERVISOR"]}>
                        <ListItem button component={ p => <Link {...p} to="/recovery"/>}>
                            <ListItemIcon>
                                <Icon>account_balance_wallet</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Pagos recuperados" />
                        </ListItem> 
                    </AccessibleSection>

                    <AccessibleSection credential={["ADMIN"]}>
                        <ListItem button component={ p => <Link {...p} to="/routes"/>}>
                            <ListItemIcon>
                                <Icon>trending_up</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Rutas" />
                        </ListItem>
                    </AccessibleSection>

                    <AccessibleSection credential={["ADMIN","SUPERVISOR","EXECUTIVE","CAPTURIST"]}>
                        <ListItem button component={ p => <Link {...p} to="/lists"/>}>
                            <ListItemIcon>
                                <Icon>view_list</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Listas" />
                        </ListItem>
                    </AccessibleSection>

                    <AccessibleSection credential={["ADMIN"]}>
                        <ListItem button component={ p => <Link {...p} to="/reports"/>}>
                            <ListItemIcon>
                                <Icon>assignment</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Reportes" />
                        </ListItem> 
                    </AccessibleSection>
                     
                    <AccessibleSection credential={["ADMIN"]}>
                        <ListItem button component={ p => <Link {...p} to="/individual-reports"/>}>
                            <ListItemIcon>
                                <Icon>description</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Reportes de operación" />
                        </ListItem> 
                    </AccessibleSection>



                    <AccessibleSection credential={["ADMIN","ACCOUNTANT"]}>
                        <ListItem button component={ p => <Link {...p} to="/receipts-list"/>}>
                            <ListItemIcon>
                                <Icon>receipt</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Recibos de pago" />
                        </ListItem> 
                    </AccessibleSection> 
                </List>
        );
    }
}