import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { Button, Grid, TextField, Icon, IconButton, CircularProgress } from '@material-ui/core';
import APIManager from '../managers/APIManager/APIManager';
import StoreManager from '../managers/StoreManger/StoreManager';

interface CurrentRouteComponentProps {
    date: string,    
}

export default class CurrentRouteComponent extends React.Component<CurrentRouteComponentProps,any> {
    
    state={
        zones:[] as any,
        isSubmiting: false,
        lastDate:''
    }

    public render(){
        
        if(this.props.date !== this.state.lastDate){
            APIManager.fetch('user/current-route.php', {filter:'limit',date: this.props.date, idExecutive: StoreManager.getState().session!.id }, (error, response)=>{
                if(error === false && response.status === 'success'){
                    console.log("****************");
                    console.log(response);
                    console.log("*****************");
                    console.log({filter:'limit',date: this.props.date, idExecutive: StoreManager.getState().session!.id} )
                   
                    var user = StoreManager.getState().session!.nick;
                    this.setState({zones: response.data[''+user], lastDate: this.props.date})
                    console.log(this.state.zones);
                } else{
                    console.log(error);    
                }
            })
        }

        if(this.state.zones!=undefined){
            return (<List >
                {Object.keys(this.state.zones).map((element:any)=>{
                    return(<div>
                        <ListItem> 
                            <ListItemText 
                            primary={<div>        
                                <Typography display="inline">{element}</Typography>                                   
                                </div>}
                            />     
                        </ListItem>

                        <Divider variant="middle" component="li" /> 
                    </div>);
                })}      
                 </List>);
        } else{
            return (<List >
                 
            </List>);
        }
           
        
    }
}

