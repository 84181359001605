import React from "react";
import AccessibleSection from "../components/AccessibleSection";
import { Button, TextField, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { History } from "../App";
import "./index.scss";
import { DAYS_OF_WEEK } from "../App";
import MainMenuList from "../components/MainMenuList";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import './Form.scss'; 
import DynamicListComponent from "../components/DynamicListComponent";
import CurrentRouteComponent from "../components/CurrentRouteComponent";
import Currency from "../components/Currency";
import APIManager from "../managers/APIManager/APIManager";
import * as Yup from 'yup';
import BeforeExpLoanPanel from "../components/BeforeExpLoanPanel";

export default class PageIndex extends React.Component<any,any>{

    public state = {
        date : new Date(),   // Fecha en la que se manejará el componente
        sumGastos: 0,
        dayExpenses: [],
        dayLoans: [],
        route: [],
        disableButton: true,
        isValidating:false,
        showDialog: false,
        response: undefined,
        balance: 0,
    }

    public componentDidMount(){

        APIManager.fetch("amount/amount-form.php", { _apiMode:"query", date:this.formatDate(this.state.date)}, (error, response) => {
            if(error === false){
                if(response.data !== "" && response.data !== undefined && response.data !== null ){
                    this.setState({disableButton: true , balance:response.data.initialAmount})
                }else{
                    this.setState({disableButton: false })
                }
            }
        });

    }
//Falta atrapar si ya existe el saldo en este dia
    public formatDate(date:Date) {
        var d = date,
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

 render(){
        return (
        <div className="screen-wraper index-page">
            <AccessibleSection credential={["CAPTURIST"]}>
                <BeforeExpLoanPanel/>
            </AccessibleSection>
            <AccessibleSection credential={["EXECUTIVE"]}>
                <div className="options-section">
 
                    <ExpansionPanel  className="btn-index">
                        <div>
                        {this.state.showDialog === true && /*this.state.response !== undefined &&*/
                                <Dialog
                                    open={this.state.showDialog}
                                    keepMounted
                                    onClose={()=>{this.setState({showDialog: false})}}>

                                <DialogTitle >{"Confirma saldo inicial"}</DialogTitle>
 
                                <DialogContent style={{ width:'450px' }}>                                                    
                                    <DialogContentText>
                                        <div style={{ justifyContent:'space-between', display:'flex', flexDirection: 'row'}}><div>Saldo del dia</div><div><Currency value={`${this.state.balance}`}></Currency></div></div>
                                    </DialogContentText>
                                </DialogContent>

                                <DialogActions>
                                <Button onClick={()=>{this.setState({showDialog: false})}} color="primary">Cancelar</Button>
 
                                <Button  color="primary"  
                                    onClick={()=>{
                                        this.setState({ isValidating: true})  
                                        console.log(this.state.balance);
                                        APIManager.fetch("amount/amount-form.php", {amount: this.state.balance ,  _apiMode:"create", date:this.formatDate(this.state.date)}, (error, response) => {
                                            if (error === false) {
                                                console.log(response)  
                                                this.setState({ disableButton:true, isValidating: false, showDialog: false})   
                                            }else{
                                                console.log(error)
                                                this.setState({isValidating: false,showDialog: false})  
                                            }
                                        })                                     
                                    }}> {this.state.isValidating?<CircularProgress  ></CircularProgress>:"Confirmar"}
                                </Button>
                            </DialogActions>
                        </Dialog> 
                        }                       
                            <Formik
                                initialValues={{
                                    date:  this.formatDate(this.state.date)
                                }}
                                onSubmit={(values) => {
                                
                                }}
                                >                                
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleBlur,
                                    handleChange
                                
                                }) => (
                                    <Form>
                                    <TextField
                                    style={{ cursor: 'pointer'}}
                                        id="date"
                                        label="Fecha"
                                        type="date"                                  
                                        name="date"
                                        onChange={(value)=>{
                                            var date = new Date(value.target.value);
                                            date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
                                    
                                            handleChange(value)
                                        
                                            var formatDate = this.formatDate(date);
                                            this.setState({date});
                                            console.log(formatDate);
                                        }}
                                        onBlur={handleBlur}
                                        className='normal'
                                        error={Boolean(errors.date) && Boolean(touched.date)}
                                        helperText={touched.date?errors.date:""}
                                        
                                        value={values.date}
                                        InputLabelProps={{
                                            shrink: true,
                                        }} />
                                    </Form>  )}
                            </Formik> 
                            
                            <div >{this.formatDate(this.state.date) == this.formatDate(new Date()) && <Formik  initialValues={{
                                    balance:  ''
                                }}
                                validationSchema={Yup.object().shape({
                                    balance: Yup.number().typeError('Ingresa una cantidad válida').positive('El saldo debe ser positivo').required('Campo requerido')
                                  })}


                                onSubmit={(values) => {

                                    console.log({amount:values.balance , _apiMode:"create", date:this.formatDate(this.state.date)});
                                    this.setState({showDialog: true, balance: values.balance})   
                                   
                                }}

                                >{({
                                    values, 
                                    errors,
                                    touched,
                                    handleBlur,
                                    handleChange
                                    
                                }) => ( 
                                    <Form style={{marginTop: '15px'}}>
                                        <TextField 
                                        disabled={this.state.disableButton}
                                        label="Saldo"
                                        type="text"
                                        name="balance"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className='normal'
                                        error={Boolean(errors.balance) && Boolean(touched.balance)}
                                        helperText={touched.balance?errors.balance:""}
                                        value={this.state.disableButton?this.state.balance:values.balance}
                                        ></TextField>

                                        
                                        <div style={{marginTop: '15px'}} className="button-container">{this.state.disableButton ===false&& <Button  type="submit" variant="contained" color="primary" >{this.state.isValidating?<CircularProgress></CircularProgress>:"Registrar saldo"}</Button>}</div>
                                    </Form>)}
                                </Formik>   
                                }
                            
                            </div>   
                        </div>
                    </ExpansionPanel>
                    
                    <ExpansionPanel  defaultExpanded expanded={true} className="btn-index">
                        <ExpansionPanelSummary>
                            <Link to={"/route/" + this.formatDate(this.state.date)}>
                                Lista del dia  {DAYS_OF_WEEK[this.state.date.getDay()]} - {this.state.date.toLocaleDateString()}
                            </Link>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{display: 'block'}}>
                            <div>
                                <CurrentRouteComponent date={this.formatDate(this.state.date)}></CurrentRouteComponent>
                            </div><br></br>
                            
                            <div className="button-container"><Button variant="contained" color="primary" className="add-record" onClick={()=>{                        
                                History.push("/route/" + this.formatDate(this.state.date));                                
                            }}>Ir a la lista</Button></div>
                        </ExpansionPanelDetails>                       
                    </ExpansionPanel>
                
                    <ExpansionPanel className="btn-index">
                        <ExpansionPanelSummary >
                            <Typography style={{width:'100%'}}>Gastos del día <span style={{float:'right', color:'#949494'}}><Currency value={this.state.sumGastos }></Currency></span></Typography>                           
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <DynamicListComponent 
                                date={this.formatDate(this.state.date)}
                                onChangeSum={(sum)=>{
                                    this.setState({sumGastos: sum})
                                }}>
                            </DynamicListComponent>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                            
                </div>
            </AccessibleSection>

            <AccessibleSection credential={["ADMIN"]}>
                <MainMenuList className="index-menu"/>
            </AccessibleSection>
        </div>
        );
    }
}