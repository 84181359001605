import React from "react";
import "./MainMenu.scss";
import { List, ListItem, ListItemIcon, ListItemText, Icon } from "@material-ui/core";
import { Link } from "react-router-dom";
import MainMenuList from "./MainMenuList";

export default class MainMenu extends  React.Component{
    public render(){
        return (
            <div className="main-menu">
                 <MainMenuList/>
            </div>
        );
    }
}