import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { Form, Formik } from 'formik';
import { Button, Grid, TextField, Icon, IconButton, CircularProgress } from '@material-ui/core';
import * as Yup from 'yup';
import APIManager from '../managers/APIManager/APIManager';
import StoreManager from '../managers/StoreManger/StoreManager';

import Currency from "./Currency";
interface DynamicListComponentpProps {
    date: string,
    onChangeSum: (sum: number) => void,
     
}


export default class DynamicListComponent extends React.Component<DynamicListComponentpProps,any> {
    
state={
    dayExpenses: [] as any, 
    items:[] as any,
    isSubmiting: false,
    suma: 0.00,
    oldSum: 0,
    lastDate:''
}

public formatDate(date:Date) {
    var d = date,
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

public deliteFromList(element, oldExpense:boolean){

    APIManager.fetch("expense/expense-form.php", {id: element.id, _apiMode: 'delete'}, (error, response)=>{

        console.log("=======>"+element.id+"=======>"+element.name+"=======>"+element.cost)
        console.log(response)
        if(error === false && response.status === 'success'){

            if(oldExpense){
                var array = [...this.state.dayExpenses];      
                var index = this.state.dayExpenses.indexOf(element) 
                array.splice(index, 1);            
                var nuevaResta = parseFloat(this.state.suma as any) - parseFloat(element.cost)
                this.setState({dayExpenses: array, suma: nuevaResta});
            }else{
                var array = [...this.state.items];      
                var index = this.state.items.indexOf(element) 
                array.splice(index, 1);            
                var nuevaResta = parseFloat(this.state.suma as any) - parseFloat(element.cost)
                this.setState({items: array, suma: nuevaResta.toFixed(2)});
            }
            this.props.onChangeSum(this.state.suma)
        } else{
           console.log(error)     
        }
    });  
}


public render(){
    var date = new Date(this.props.date);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
    var formatDate = this.formatDate(date);
    console.log(formatDate);
    if(this.props.date != this.state.lastDate){
        APIManager.fetch('expense/expense-form.php', {_apiMode:'list', date: this.props.date, idExecutive: StoreManager.getState().session!.id }, (error, response)=>{
            if(error === false && response.status === 'success'){
                console.log(response); 
                var oldSum=0;
                response.data.map((element:any)=>{
                    oldSum=parseFloat(oldSum as any)+parseFloat(element.cost);
                })
                this.setState({dayExpenses: response.data, suma: oldSum, lastDate: this.props.date, items: []})
                this.props.onChangeSum(this.state.suma) 
            }
        })
    }

if(this.props.date !== formatDate /*&& SecurityManager.hasCredential('ADMIN')===false*/){
    return(  
        <div style={{width:'100%'}}>
        <List >
            {this.state.dayExpenses.map((element:any)=>{
                return(<div>
                    <ListItemText 
                        primary={<div>  
                            <Typography display="inline">{element.name}</Typography>
                            <Typography display="inline" style={{color:"#949494", float: 'right', display:'block'}}><Currency value={element.cost}></Currency></Typography>                           
                        </div>}/>                               
                    <Divider variant="middle" component="li" /> 
                </div>);
            })} 
            
             
            {this.state.items.map((element)=>{
                return(<div>
                        <ListItemText 
                            primary={<div>
                                <Typography display="inline">{element.name}</Typography>
                                <Typography display="inline" style={{color:"#949494", float: 'right', display:'block'}}><Currency value={element.cost}></Currency></Typography> 
                                </div>}/>
                        <Divider variant="middle" component="li" /> 
                </div>);
            })}      
        </List>
 
        </div>
    )
}
      return (
            <div>   
                <List >
                {this.state.dayExpenses.map((element:any)=>{
                        return(<div>
                            <ListItem alignItems="flex-start">
                                <IconButton  size="small" onClick={()=>{this.deliteFromList(element, true)}} > <Icon>clear</Icon></IconButton>
                                <ListItemText 
                                    primary={<div>
                                        
                                        <Typography display="inline">{element.name}</Typography>
                                        <Typography display="inline" style={{color:"#949494", float: 'right'}}><Currency value={element.cost}></Currency></Typography>
                                        
                                        </div>}
                                        />
                                       
                                </ListItem>
                                
                                <Divider variant="middle" component="li" /> 
                        </div>);
                    })} 
                    
                    {this.state.items.map((element)=>{
                        return(<div>
                            
                            <ListItem alignItems="flex-start">
                                <IconButton  size="small" onClick={()=>{this.deliteFromList(element, false)}} > <Icon>clear</Icon></IconButton>
                                <ListItemText 
                                    primary={<div>
                                        
                                        <Typography display="inline">{element.name}</Typography>
                                        <Typography display="inline" style={{color:"#949494", float: 'right'}}><Currency value={element.cost}></Currency></Typography>
                                        
                                        </div>}
                                        />
                                       
                                </ListItem>
                                
                                <Divider variant="middle" component="li" /> 
                        </div>);
                    })}      
                </List>
      
        <Formik key={Math.floor((Math.random() * 1000) + 1)}
            initialValues={{
            _apiMode:'create',
                name:'',
                cost:''
            } as any}

            validationSchema= {Yup.object().shape({
                name: Yup.string().required('Requerido'),
                cost: Yup.number().required('Requerido'),
            })}

            onSubmit={(values, { setSubmitting }) => {
                values.date = this.props.date;
                   
                this.setState({isSubmiting:true});               
                APIManager.fetch("expense/expense-form.php", values, (error, response)=>{
                    console.log(error);
                    console.log(response);
                    if(error === false && response.status === 'success'){
                        

                        var nuevaSuma = parseFloat(this.state.suma as any) + parseFloat(response.data.cost)
                      
                       

                        var newItem ={name: response.data.name, cost: response.data.cost, id:response.data.id}
                        this.setState({items: [...this.state.items, newItem], isSubmiting:false, suma: nuevaSuma.toFixed(2) });
                        this.props.onChangeSum(this.state.suma)                    
                        console.log(response);
                    } else{
                        this.setState({isSubmiting:false });
                        console.log(error) ;    
                    }
                });
                setSubmitting(false);
            }}>  
      
              

      {({values, errors, touched, handleChange, handleBlur,handleSubmit, isSubmitting, }) => (
                <Form style={{marginTop: '15px'}}>
                  <Grid container spacing={2}>
                      <Grid item xs={12} >
                          <TextField
                              name='name'
                              label="Descripción"
                              type="text" 
                              helperText={Boolean(touched.name)?errors.name:""}
                                    error={Boolean(errors.name) && Boolean(touched.name)}
                                    className='normal'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name || ''}/>
                      </Grid>
                      <Grid item xs={12} >
                          <TextField
                              name="cost"
                              label="Monto" 
                              type="text" 
                              helperText={Boolean(touched.cost)?errors.cost:""}
                                    error={Boolean(errors.cost) && Boolean(touched.cost)}
                                    className='normal'        
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.cost || ''}/>
                      </Grid> 
                      <Grid style={{textAlign:'center', marginTop:'15px'}} item xs={12} >
                        <Button  type="submit" color="primary"  variant="contained" >{this.state.isSubmiting?<CircularProgress></CircularProgress>:'Agregar'}</Button>
                      </Grid>             
                  </Grid>
              </Form>
              )}
          </Formik> 
      </div>
        );

    }
 
}
