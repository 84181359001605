import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import { CardContent, Typography } from '@material-ui/core';
import LoginForm from './LoginForm';

const styles = {
    container: {
        display: 'flex',
        width:"100%",
        justifyContent:'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: '#EEEEEE',  
    },
    card: {
        display: 'flex',  
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent:'center',
        width: 350,
        height: 300,
        
    },
    cardContent: {
        width: '90%',
        height: '90%',
        display: 'flex',  
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent:'center',
        
    },       
} as any;

class Login extends Component {
    render() {
        return (
            <div style={styles.container}>
            <Card elevation={4} style={styles.card}>
                <CardContent style={styles.cardContent}>
                    <Typography color="primary">Iniciar sesión</Typography>  
                    <LoginForm></LoginForm> 
                </CardContent>
            </Card>
            </div>
        );
    }
}

export default Login;