import React, { Component } from 'react';
import Navigator from './components/Navigator';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import StoreManager from './managers/StoreManger/StoreManager';
import APIManager from './managers/APIManager/APIManager';
import SystemReducer from './managers/StoreManger/reducers/SystemReducer';
import { StateSession } from './managers/StoreManger/state/StateModel';
import MainContainer from './MainContainer';

import './App.scss';
import { BrowserRouter, Router } from 'react-router-dom';

export const DEV_MODE = process.env.NODE_ENV === "development";
export const API_URL = DEV_MODE ? "/emprestamex/system/api" : "./system/api";
export const DAYS_OF_WEEK = [ "Domingo" , "Lunes" , "Martes" , "Miercoles" , "Jueves" , "Viernes" , "Sábado"];

export const History = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

History.listen(() => {
  window.scrollTo(0, 0);
})


export default class App extends Component<{session?:StateSession}> {

  componentDidMount(){
    APIManager.fetch("common/boot.php",{},(error,response)=>{
        console.log(response)
        StoreManager.dispatch(new SystemReducer.boot().makeAction(response));
    })
  }

  render() {
    return (
      <Provider store={StoreManager.getStore()}>
          <Router history={History}>
            <Navigator></Navigator>
            <MainContainer></MainContainer>
          </Router>
      </Provider>
    )
  }
}